import React, { useState, useContext, useEffect, Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import classNames from "classnames"

import Layout from "../../../Layout"
import Container from "../../../Layout/Container"
import Section from "../../../Elements/Section"
import Message from "../../../Elements/Message"
import ActionButtons from "../../../Elements/ActionButtons"
import { generateFormField } from "../../../Elements/Form/services/form"

import { DoctorEnrollmentContext } from "../../EnrollmentContext/DoctorEnrollmentContext"
import { formSchema } from "../../utils/formSchema"
import styles from "../../utils/enrollment.module.scss"
import { generateInitialValues } from "../../utils/generateInitialValues"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import addSupTags from "../../utils/addSupTags"
import trimObjectWhitespaces from "../../utils/trimObjectWhitespaces"

let { parseFormField } = require("../../../../services/airtable")

const DoctorEnrollmentForm = ({ pageContext }) => {
  const [loading, setLoading] = useState(false)
  const { module, pageContent } = pageContext
  const { doctorEnrollmentState, doctorEnrollmentDispatch } = useContext(
    DoctorEnrollmentContext
  )

  useEffect(() => {
    doctorEnrollmentDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [doctorEnrollmentDispatch])

  let termsAndConditions = pageContent[0]?.body?.childMarkdownRemark?.html
  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const handleSubmit = async (values) => {
    values = trimObjectWhitespaces(values)
    setLoading(true)

    let documents = doctorEnrollmentState?.documents

    doctorEnrollmentDispatch({
      type: "SAVE_DOCTOR_ENROLLMENT",
      payload: values,
    })

    await doctorEnrollmentDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: { ...doctorEnrollmentState, ...values, documents },
    })

    navigate(pageContext.nextPath)
  }

  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
    ...doctorEnrollmentState,
  }

  return (
    <Layout
      {...module}
      pageContext={pageContext}
      currentStep={1}
      totalSteps={1}
      display={{
        footer: false,
        helpCenterBanner: false,
        bottomMargin: true,
        progressBar: true,
      }}
    >
      <Container isCentered>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  id={section?.sectionId}
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  className="mt-1 mb-3"
                  isSectionRequired={section.isSectionRequired}
                  helper={section?.helper}
                >
                  {section?.message && (
                    <p
                      className="has-text-primary mb-2"
                      dangerouslySetInnerHTML={{
                        __html: addSupTags(section?.message),
                      }}
                    />
                  )}
                  {section?.section === "Consent and Authorization" && (
                    <Fragment>
                      <div
                        className={classNames(
                          "message-body my-2 ",
                          styles["collapsible"]
                        )}
                      >
                        <div className="content">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: termsAndConditions,
                            }}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values,
                            setFieldValue,
                            state: doctorEnrollmentState,
                            dispatch: doctorEnrollmentDispatch,
                            type: "doctor-enrollment",
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{
                  label: <FontAwesomeIcon icon={faChevronLeft} />,
                  link: pageContext.backPath,
                }}
                submit={{
                  label: "Next: Summary",
                  loading: loading,
                  //disabled: doctorEnrollmentState?.documents?.length === 0,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default DoctorEnrollmentForm
